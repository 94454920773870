$(function(){
    $(window).resize(function(){
        var _winW=$(window).innerWidth();
        if(_winW>960){
          // $('.nav_group').css('display','block');
          // $('.lSAction a').css('display','block');
          $('.nav_group').show();
            let $box=$('.box')
            let $top=$box.find('.top')
            let $menu=$('.menu')
            $top.css({
                height:'initial'
            })

          $('.single').find('input').prop("checked", false)
          // $('.single-item').css({display:'none'})
        }else{


        }
    }).resize();

    $(function(){
        var _winW=$(window).innerWidth();
        if(_winW>960){
            // $('.nav_group').css('display','block');
            $('.lSAction a').css('display','block');
            $('.nav_group').show();
        }else{
            $('.lSAction a').css('display','none');
            //$('.nav_group').hide();
        }
        $('ul.menu').show();
        $('.menu_switch').click(function(){
            $('.nav_group').slideToggle();
            // console.log('aa')
            var $this=$(this)
            let $box=$('.box')
            let $top=$box.find('.top')
            let $menu=$('.menu')
            if($this.hasClass('opened')){
                // 選單關著，叉叉改成漢堡；
                $this.removeClass('opened');
                $this.html("<i class='fa-solid fa-bars'></i>");
                $top.css({
                    height:'auto'
                })
            }else{
                // 選單開著，漢堡改成叉叉；
                $this.addClass('opened');
                $this.html("<i class='fa-solid fa-xmark'></i>");
                $top.css({
                    height:'100vh'
                })
            }
        })
    })
})